import React from "react";

const TermsAndConditions = ({data}) => {
  return (
    <div className="container mt-5">
      <h1>{data.termsAndConditions.title}</h1>
      {data.termsAndConditions.sections.map((section, index) => (
        <div key={index}>
          <h4>{section.heading}</h4>
          {section.content && <p>{section.content}</p>}
          {section.subSections && (
            <ul>
              {section.subSections.map((subSection, subIndex) => (
                <li key={subIndex}>
                  <h5>{subSection.subHeading}</h5>
                  <p>{subSection.content}</p>
                </li>
              ))}
            </ul>
          )}
          {section.contactDetails && (
            <ul>
              {section.contactDetails.map((contact, contactIndex) => (
                <li key={contactIndex}>
                  <span>{contact.label}:</span>{" "}
                  {contact.type === "email" ? (
                    <a href={`mailto:${contact.value}`}>{contact.value}</a>
                  ) : contact.type === "phone" ? (
                    <a href={`tel:${contact.value}`}>{contact.value}</a>
                  ) : (
                    contact.value
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default TermsAndConditions;

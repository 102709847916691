import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "remixicon/fonts/remixicon.css";
import "./App.css";

// Components
import NavBar from "./NavBar/NavBar";
import Home from "./Pages/Home/Home";
import Features from "./Pages/Features/Features";
import About from "./Pages/About/About";
import Enroll from "./Pages/Enroll/Enroll";
import Contact from "./Pages/Contact/Contact";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import { fetchGitHubData } from "./githubdata/githubdata";
import { Spin } from "antd";
// import data from "./Data/data.json"
AOS.init();

const POLLING_INTERVAL = 2000; // 2 seconds

const App = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Spinner state
  const [spinning, setSpinning] = useState(false);
  const [percent, setPercent] = useState(0);

  // Function to simulate spinner progress
  const showLoader = () => {
    setSpinning(true);
    let ptg = -10;
    const interval = setInterval(() => {
      ptg += 5;
      setPercent(ptg);
      if (ptg > 120) {
        clearInterval(interval);
        setSpinning(false);
        setPercent(0);
      }
    }, 100);
  };

  const fetchData = async () => {
    try {
      showLoader(); // Show spinner
      const data = await fetchGitHubData();
      setData(data);
      setLoading(false);
      setSpinning(false); // Hide spinner after data is loaded
    } catch (error) {
      setLoading(false);
      setSpinning(false); // Hide spinner if there is an error
    }
  };

  useEffect(() => {
    fetchData(); // Initial fetch
    const intervalId = setInterval(fetchData, POLLING_INTERVAL); // Poll every 2 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  // Show spinner while loading
  if (loading) return <Spin spinning={spinning} percent={percent} fullscreen />;
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <NavBar />
            <Home data={data} />
            <Features data={data} />
            <About data={data} />
            <Enroll data={data} />
            <Contact data={data} />
          </>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditions data={data} />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy data={data} />} />
    </Routes>
  );
};

export default App;

import React from "react";


const PrivacyPolicy = ({data}) => {
  return (
    <div className="container mt-5">
      <h1>{data.privacyPolicy.title}</h1>
      <p>
        <strong>Effective Date: {data.privacyPolicy.effectiveDate}</strong>
      </p>
      {data.privacyPolicy.sections.map((section, index) => (
        <div key={index}>
          <h3>{section.heading}</h3>
          <p>{section.content}</p>
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;

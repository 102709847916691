import axios from "axios";

const GITHUB_TOKEN = "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";
const REPO_OWNER = "Cloud-Garage-LLP";
const REPO_NAME = "TikTik_Landing_Page";
const FILE_PATH = "src/Data/data.json";
export const fetchGitHubData = async () => {
  try {
    const response = await axios.get(
      `https://api.github.com/repos/${REPO_OWNER}/${REPO_NAME}/contents/${FILE_PATH}`,
      {
        headers: {
          Authorization: `token ${GITHUB_TOKEN}`,
          Accept: "application/vnd.github.v3.raw",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
